<template>
  <NuxtLayout>
    <div class="no-found">
      <div class="no-found__wrp">
        <div class="no-found__content">
          <div
            class="no-found__img-wrp"
            @click="clearError({ redirect: getCurrentLang().path })"
          >
            <img src="/img/404-image.svg" alt="error 404" />
          </div>

          <h3 class="no-found__title">
            {{ _T("@Page not found") }}
          </h3>
          <p class="no-found__social-title">
            {{ _T("@Our social networks") }}
          </p>

          <div class="no-found__social-links">
            <a
              :href="socials.facebook"
              target="_blank"
              class="no-found__social-link"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 2.23242H14C12.6739 2.23242 11.4021 2.75921 10.4645 3.69689C9.52678 4.63457 9 5.90634 9 7.23242V10.2324H6V14.2324H9V22.2324H13V14.2324H16L17 10.2324H13V7.23242C13 6.96721 13.1054 6.71285 13.2929 6.52532C13.4804 6.33778 13.7348 6.23242 14 6.23242H17V2.23242Z"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>

            <a
              :href="socials.youtube"
              target="_blank"
              class="no-found__social-link"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 12.2324L10.5 14.2324V10.2324L14 12.2324Z"
                  fill="#1F1F1F"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 12.9404V11.5253C2 8.62996 2 7.1823 2.90549 6.25083C3.81099 5.31936 5.23656 5.27904 8.08769 5.1984C9.43873 5.16019 10.8188 5.13281 12 5.13281C13.1812 5.13281 14.5613 5.16019 15.9123 5.1984C18.7634 5.27904 20.189 5.31936 21.0945 6.25083C22 7.1823 22 8.62996 22 11.5253V12.9404C22 15.8357 22 17.2834 21.0945 18.2148C20.189 19.1463 18.7635 19.1866 15.9124 19.2673C14.5613 19.3055 13.1812 19.3329 12 19.3329C10.8188 19.3329 9.43867 19.3055 8.0876 19.2673C5.23651 19.1866 3.81097 19.1463 2.90548 18.2148C2 17.2834 2 15.8357 2 12.9404Z"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                />
              </svg>
            </a>

            <a
              :href="socials.instagram"
              target="_blank"
              class="no-found__social-link"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 16.2324C14.2091 16.2324 16 14.4416 16 12.2324C16 10.0233 14.2091 8.23242 12 8.23242C9.79086 8.23242 8 10.0233 8 12.2324C8 14.4416 9.79086 16.2324 12 16.2324Z"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 16.2324V8.23242C3 5.471 5.23858 3.23242 8 3.23242H16C18.7614 3.23242 21 5.471 21 8.23242V16.2324C21 18.9938 18.7614 21.2324 16 21.2324H8C5.23858 21.2324 3 18.9938 3 16.2324Z"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                />
                <path
                  d="M17.5 6.74242L17.51 6.73131"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>

            <a
              :href="socials.telegram"
              target="_blank"
              class="no-found__social-link"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5.23242L2 12.7324L9 13.7324M21 5.23242L18.5 20.2324L9 13.7324M21 5.23242L9 13.7324M9 13.7324V19.2324L12.2488 15.9553"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup>
import { useLanguageStore } from "~/store/languageStore";
import socials from "~/utils/socials";

const { path } = useRoute();

const languageStore = useLanguageStore();

const getCurrentLang = () => {
  if (path.startsWith("/ua")) {
    return {
      lang: "ua",
      path: "/",
    };
  }

  return {
    lang: "ru",
    path: "/ru",
  };
};

languageStore.setLanguage(getCurrentLang().lang);
</script>

<style lang="scss" scoped>
.no-found {
  height: 100%;
  background-color: var(--color-sky-lighter);

  padding: 72px 16px 225px;

  @include bigMobile {
    padding: 0;
  }

  &__wrp {
    max-width: 955px;
    width: 100%;

    background-color: white;
    border-radius: 36px;

    margin: 0 auto;
    padding: 60px 16px 48px;

    @include bigMobile {
      max-width: 100%;
      border-radius: 0;
    }

    @include mobile {
      padding: 64px 0 136px;
    }
  }

  &__content {
    @include flex-container(column, center, center);
  }

  &__img-wrp {
    margin-bottom: 48px;

    cursor: pointer;

    @include mobile {
      margin-bottom: 32px;
      padding: 0 75px;
    }
  }

  &__title {
    text-transform: uppercase;
    @include font(25, 35, 700);
    letter-spacing: 0.5px;

    margin-bottom: 86px;

    @include bigMobile {
      @include font(20, 30, 600);
    }

    @include mobile {
      text-transform: none;
      margin-bottom: 64px;
    }
  }

  &__social-title {
    @include font(18, 24, 500);
    letter-spacing: 0.02em;
    color: var(--color-primary-base);

    margin-bottom: 36px;
  }

  &__social-links {
    @include flex-container(row, space-around, center);

    gap: 24px;
  }

  &__social-link {
    & svg {
      transition: transform 0.2s ease-in-out;
    }

    &:hover svg {
      transform: scale(1.1);
    }
  }
}
</style>
