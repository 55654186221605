<template>
  <div
    ref="refLeadCapture"
    class="lead-capture"
    tabindex="0"
    @keydown.esc="onCloseModal"
  >
    <div v-on-click-outside="onCloseModal" class="lead-capture__wrapper">
      <img
        class="lead-capture__mascot"
        :src="'/img/lead-capture/mascot.png'"
        alt="mascot"
      />
      <div class="lead-capture__content">
        <div class="lead-capture__header">
          <p class="lead-capture__header-title">
            {{ _T("@Subscription popup title") }}
          </p>
          <ButtonClose @click.prevent="onCloseModal" />
        </div>
        <ButtonClose
          color="var(--color-primary-base)"
          class="lead-capture__close"
          @click.prevent="onCloseModal"
        />
        <form action="" class="lead-capture__form">
          <p class="lead-capture__title">
            {{ _T("@Description subscription pop-up") }}
          </p>
          <InputText
            v-model="form.name"
            :validate="v.name"
            :maxlength="255"
            :caption="_T('@Name')"
            autocomplete-type="given-name"
          />
          <InputEmail
            v-model="form.email"
            :validate="v.email"
            caption="E-mail"
          />
          <BaseCheckbox
            v-model="form.isAgreementAccepted"
            :validate="v.isAgreementAccepted"
            color="var(--color-primary-light)"
          >
            <span>
              {{ _T("@I agree with") }}
              <MyLink name="privacy-policy" target="_blank">
                {{ _T("@privacy policy") }}
              </MyLink>
            </span>
          </BaseCheckbox>
          <ButtonPrimary
            type="submit"
            :aria-label="_T('@Send')"
            button-width="363px"
            :disabled="sendForm.isHandled"
            @click.prevent="onSubmit"
          >
            {{ _T("@Send") }}
          </ButtonPrimary>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import InputText from "~/modules/shared/inputs/InputText.vue";
import InputEmail from "~/modules/shared/inputs/InputEmail.vue";
import {
  successfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import {
  emailValidate,
  maxLengthValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import { modalFormStore } from "~/modules/shared/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useAPI } from "~/uses/useMyFetch";
import BaseCheckbox from "~/modules/shared/inputs/BaseCheckbox.vue";
import ButtonClose from "~/modules/shared/buttons/ButtonClose.vue";
import { vOnClickOutside } from "@vueuse/components";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";
import { useAppearanceStore } from "~/store/appearanceStore";

const modalStore = useModalStore();
const formStore = modalFormStore();
const userStore = useUserStore();
const { setModalHasShown } = useAppearanceStore();

const refLeadCapture = ref(null);

const { currentUser: user } = storeToRefs(userStore);

onMounted(() => {
  refLeadCapture.value.focus();
  document.documentElement.style.overflow = "hidden";

  if (null !== user.value) {
    form.name = user.value.name.firstName;
    form.email = user.value.email;
  }

  formStore.addForm({ v, id: "discount" });
});

const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Successful modal text"),
  isWithTimeout: true,
};

const form = reactive({
  name: "",
  email: "",
  isAgreementAccepted: true,
});

const rules = computed(() => ({
  name: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 254),
  },
  isAgreementAccepted: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

function onCloseModal() {
  setModalHasShown("leadCapture");
}

const sendForm = useSingletonHandler(() =>
  useAPI("/marketing/event", {
    method: "POST",
    body: {
      event: "subscriptionWithCoupon",
      email: form.email,
      isConfirmed: true,
      name: form.name,
    },
  }).then(() => {
    onCloseModal();

    modalStore.toggleModal(successfulModal, successfulData);
  }),
);

const onSubmit = async () => {
  if (!formStore.isFormsReady()) {
    return;
  }

  return await sendForm.handle();
};

onUnmounted(() => {
  formStore.resetForms();
  document.documentElement.style.overflow = "auto";
});
</script>

<style lang="scss" scoped>
.lead-capture {
  display: flex;

  position: fixed;
  inset: 0;
  z-index: 510;

  background-color: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);

  cursor: auto;

  &__wrapper {
    position: relative;

    max-width: 640px;
    width: 100%;

    margin: auto;

    @include mobile {
      height: 100%;
      max-width: none;

      @include flex-container(column, space-between);

      background-color: #f0f0f0;

      overflow: auto;
    }
  }

  &__content {
    position: relative;

    height: 618px;

    @include flex-container(column, flex-end, center);

    background-image: url("/img/lead-capture/coupons.png"),
      url("/img/lead-capture/discounts.png"),
      url("/img/lead-capture/main-background.png");
    background-position: top, top, bottom;
    background-repeat: no-repeat;

    padding: 24px 16px;

    @include mobile {
      height: 100%;

      justify-content: space-between;

      background-size: contain;

      padding: 0 0 24px;
    }
  }

  &__form {
    width: 100%;

    @include flex-container(column, center, center);
    gap: 24px;
  }

  &__mascot {
    position: absolute;
    left: -250px;
    top: -30px;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }

  &__header {
    display: none;
    width: 100%;

    background-color: rgba(255, 255, 255, 0.9);

    padding: 8px 16px;

    @include mobile {
      @include flex-container();
    }
  }

  &__title {
    width: min(100%, 363px);
    text-align: center;

    @include font(18, 26, 700);
    letter-spacing: 0.36px;
  }

  &__close {
    position: absolute;

    right: -16px;
    top: 50%;
    transform: translateY(-50%);

    @include fixedHW(40px);

    background-color: white;
    border-radius: 50%;

    @include mobile {
      display: none;
    }
  }
}
</style>
