import { useStrapiApi } from "~/uses/useMyFetch";
import { cleanUrlName } from "~/utils/strings.utils";
import { useCategoriesStore } from "~/store/categoriesStore";

export default defineNuxtRouteMiddleware(async (to, from) => {
  //Redirect настройки
  //
  if (import.meta.server) {
    const { data: redirect } = await useStrapiApi("/redirects/lookup", {
      query: {
        sourcePath: to.path,
      },
      timeout: 1000,
    }).catch(() => {
      return {
        targetPath: null,
      };
    });

    if (redirect.targetPath !== null) {
      return navigateTo(redirect.targetPath);
    }
  }

  //Выброс ошибок на несуществующие пути
  //
  const error = useError();

  if (import.meta.client && error.value && to.fullPath !== from.fullPath) {
    window.location.href = to.fullPath;
  }

  if (cleanUrlName(to.name) === "category") {
    const categoryStore = useCategoriesStore();
    const category = categoryStore.getCategoryBySlug(to.params.categorySlug);

    if (undefined === category) {
      throw createError({ statusCode: 404, fatal: true });
    }
  }

  if (cleanUrlName(to.name) === "search" && !to.query.searchQuery) {
    throw createError({ statusCode: 404, fatal: true });
  }
});
