import { useLanguageStore } from "./languageStore";
import { useAsyncAPI } from "~/uses/useMyFetch";

export const useI18nStore = defineStore("i18nStore", {
  state: () => {
    return {
      translations: null,
    };
  },
  actions: {
    async fetch() {
      await useAsyncAPI("/shared/export/translations").then(({ data }) => {
        this.translations = data;
      });
    },
  },
  getters: {
    getTranslation: (state) => {
      const language = useLanguageStore().getUserLanguage;

      return (key) => {
        return state.translations[key]?.[language.name] ?? key;
      };
    },
  },
});
