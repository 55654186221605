<template>
  <Maintenance v-if="isMaintenance" />
  <NuxtLayout v-else>
    <NuxtPage />
    <ClientOnly>
      <TransitionGroup tag="div" name="show-modal">
        <Component
          :is="modals[modalName]"
          v-for="modalName in getActiveModalsName"
          :key="modalName"
        />
        <LeadCaptureModal
          v-if="
            !appearanceStore.modals.leadCapture.wasShown &&
            isLeadCaptureTimeoutReady
          "
        />
        <AdPopup
          v-if="!appearanceStore.modals.ad.wasShown && isAdTimeoutReady"
        />
      </TransitionGroup>
      <WidgetHelper />
    </ClientOnly>
  </NuxtLayout>
</template>

<script setup>
import { useLanguageStore } from "~/store/languageStore";
import { useUtm } from "~/uses/useUtm";
import {
  modals,
  setNewPasswordModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import { useMaintenanceStore } from "~/store/maintenanceStore";
import Maintenance from "~/modules/maintenance/Maintenance.vue";
import { useAPI } from "~/uses/useMyFetch";
import { useUserStore } from "~/store/userStore";
import WidgetHelper from "~/modules/widgetHelper/WidgetHelper.vue";
import AdPopup from "~/modules/shared/adPopup/AdPopup.vue";
import { useAppearanceStore } from "~/store/appearanceStore";
import LeadCaptureModal from "~/modules/shared/modals/LeadCaptureModal.vue";

// swiper change lang issue (eventListener error)
// https://github.com/nolimits4web/swiper/issues/4043

const config = useRuntimeConfig();
const route = useRoute();

const languageStore = useLanguageStore();
const userStore = useUserStore();
const modalStore = useModalStore();
const maintenanceStore = useMaintenanceStore();

const appearanceStore = useAppearanceStore();
const isAdTimeoutReady = useTimeout(5000);

const isLeadCaptureTimeoutReady = useTimeout(10000);

const { getIsEnabled: isMaintenance } = storeToRefs(maintenanceStore);
const { getUserLanguage } = storeToRefs(languageStore);

const { getActiveModalsName } = storeToRefs(modalStore);

const isRouteMatched = (name) => {
  return name === route.name.replace(/^[a-z]+_/, "");
};

onMounted(() => {
  if (import.meta.client) {
    userStore.fetch().finally(() => {
      const utm = useUtm(route.query);

      if (utm.isExists) {
        useAPI("/account/utm", {
          method: "POST",
          body: { ...utm.parameters },
        });
      }

      if (route.query.recovery) {
        modalStore.toggleModal(setNewPasswordModal);
      }
    });
  }
});

//Language settings
languageStore.setLanguage(route.name.match(/[^_]*/)[0]);

if (!isMaintenance.value) {
  useHead({
    htmlAttrs: {
      lang: () => getUserLanguage.value.name,
    },
    link: [
      {
        rel: "alternate",
        hreflang: "uk",
        href: () => {
          if (isRouteMatched("home")) {
            return config.public.appUrl;
          }

          if (getUserLanguage.value.name === "ru") {
            return config.public.appUrl + "/ua" + route.path;
          }

          return config.public.appUrl + route.path;
        },
      },
      {
        rel: "alternate",
        hreflang: "ru",
        href: () => {
          if (isRouteMatched("home")) {
            return config.public.appUrl + "/ru";
          }

          if (getUserLanguage.value.name === "uk") {
            const replacedPath = route.path.replace("/ua", "");

            return config.public.appUrl + replacedPath;
          }

          return config.public.appUrl + route.path;
        },
      },
      {
        rel: "canonical",
        href: () => {
          return config.public.appUrl + route.path;
        },
      },
    ],
  });
}
</script>

<style lang="scss">
:root {
  --swiper-theme-color: #009b3e;
}

.show-modal-enter-active,
.show-modal-leave-active {
  transition: opacity 0.3s ease;
}

.show-modal-leave-to,
.show-modal-enter-from {
  opacity: 0;
}

.expand-item-enter-from,
.expand-item-leave-to {
  transform: translateY(-30px);
  opacity: 0;

  transition:
    transform 0.4s ease,
    opacity 0.4s ease;
}

.expand-item-enter-to {
  transform: translateY(0px);
  transition: transform 0.4s ease;
}

.show-menu-enter-active,
.show-menu-leave-active {
  transition: all 0.3s ease;
}

.show-menu-enter-from,
.show-menu-leave-to {
  transform: translateX(100%);
}

.show-menu-enter-to,
.show-menu-leave-from {
  transform: translateX(0);
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes arrow-move-forward {
  0% {
    transform: translateX(0%);
  }

  30% {
    transform: translateX(100%);
    opacity: 0;
  }

  70% {
    opacity: 0;
    transform: translateX(-150%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
</style>
