import { useUserStore } from "~/store/userStore";
import { languageConfig } from "~/config/language.config";

export const useLanguageStore = defineStore("languageStore", {
  state: () => {
    return {
      language: null,
    };
  },
  actions: {
    setLanguage(locale) {
      this.language = languageConfig.find(
        (language) => language.synonym === locale,
      );
    },
    changeLanguage(language) {
      if (language.name === this.language.name) {
        return;
      }

      this.language = language;
      this.onChangeLanguage();
    },
    onChangeLanguage() {
      const userStore = useUserStore();
      const { name, query } = useRoute();

      if (null !== userStore.currentUser) {
        userStore.updateLocale(this.language.name);
      }

      return navigateTo(
        {
          name: name.replace(/[^_]*/, this.language.synonym),
          query,
        },
        {
          replace: true,
        },
      );
    },
  },
  getters: {
    getUserLanguage: (state) => state.language,
    getLocalizedRoute: (state) => {
      return (routeName) => {
        return state.language.synonym + "_" + routeName;
      };
    },
  },
});
